.embla {
  overflow: hidden;
}

.embla__container {
  display: flex;
}

.embla__slide {
  -moz-box-flex: 0;
  flex: 0 0 100%;
  min-width: 0;
}

.embla__viewport {
  overflow: hidden;
}

@media (width >= 1024px) {
  .embla__slide {
    -moz-box-flex: 0;
    flex: 0 0 33%;
  }
}
/*# sourceMappingURL=index.adb7929c.css.map */
