.embla {
	overflow: hidden;
}
.embla__container {
	display: flex;
}

.embla__slide {
	flex: 0 0 100%;
	min-width: 0;
}
.embla__viewport {
	overflow: hidden;
}

@media (min-width: 1024px) {
	.embla__slide {
		flex: 0 0 33%;
	}
}
